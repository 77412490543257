import App from './G_App.svelte'

let app

app = new App({
	target: document.body,
	hydrate: true,
	props: {
		context: window.context
	},
	intro: true
})
export default app;