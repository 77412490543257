<script>
  import { afterUpdate } from 'svelte'
  import { i18n, i18nBr, fuc as doFuc} from './h.js'
  import { lang } from './stores.js'

  export let id=false
  export let fuc=false
  export let html=false
  let cl=$$props.class || ''
  //$: console.log(id)

  let me, fallbackElement

	afterUpdate(() => {
    if(me && !id) id=me.innerHTML
    if(me && id && html) me.innerHTML=fuc?doFuc(i18nBr(id)):i18nBr(id)
    else if(me && id) me.innerHTML=fuc?doFuc(i18n(id)):i18n(id)

    //if(me && !me.id) me.id=me.innerHTML
    //if(me && id) me.innerHTML=i18n(id)
    //else if(me && me.id)	me.innerHTML=i18n(me.id)
    //if(debug && !fallbackElement) console.log(`I18n : ${me.id}`)
	})

</script>
<span class={cl} bind:this={me} lang="{$lang}">
  <slot>
    <div bind:this={fallbackElement}/>
  </slot>
</span>
