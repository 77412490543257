<script>
  import { conf } from './stores.js'
  let classes=''
  let cl=$$props.class || ''
  export let faType=$conf.faType || "fa-light"
  export let sharp=$conf.faSharp || false //sharp is a subtype of solid/regular/light/thin...
  export let icon=""
  export let onclick=false
  export let nofw=false
  export let title=""
  
  export let success=false 
  export let warning=false
  export let danger=false
  export let info=false
  export let ms=false
  export let me=false
  export let x2=false
  export let x3=false
  export let x4=false
  export let solid=null
  export let regular=null
  export let light=null
  export let thin=null
  export let duotone=null
  export let brands=null
  export let type=""
  export let spin=null
  export let fade=null
  export let beat=null

  if(icon) {
    classes=`fa-${icon} `
    icon=""
  }

  if(type) faType=type

  if(solid===false) light=true
  
  if(faType=="fa-solid") solid=true
  if(faType=="fa-regular") regular=true
  if(faType=="fa-thin") thin=true
  if(faType=="fa-light") light=true
  if(faType=="fa-duotone") duotone=true
  if(faType=="fa-brands") brands=true

  if(brands) solid=regular=thin=light=duotone=false
  if(solid) regular=thin=light=duotone=false
  if(regular) solid=thin=light=duotone=false
  if(thin) solid=regular=light=duotone=false
  if(light) solid=regular=thin=duotone=false
  if(duotone) solid=regular=thin=light=false

  for (const [key] of Object.entries($$restProps)) {
    if(key=="2x") x2=true
    else if(key=="3x") x3=true
    else if(key=="4x") x4=true
    else classes+=`fa-${key} `
  }
</script>

{#if classes}
  <i on:click={onclick} class:pointer={onclick} 
    class:success class:warning class:danger class:info 
    class:ms-2={ms} class:me-2={me}
    class:fa-2x={x2} class:fa-3x={x3} class:fa-4x={x4}
    class:fa-solid={solid}
    class:fa-regular={regular}
    class:fa-light={light}
    class:fa-thin={thin}
    class:fa-duotone={duotone}
    class:fa-brands={brands}
    class:fa-sharp={sharp}
    class:fa-spin={spin}
    class:fa-fade={fade}
    class:fa-beat={beat}
    
    class="{classes} {cl}" class:fa-fw={!nofw} {title}>
  </i>
{/if}
