<script>
  import { conf, lang, wsc } from './stores.js'
  import { init, inArray, asArray, locale, spyScroll, i18n, fuc, openHref, setCssVars, thisYear } from './h.js'
  import Switch from './G_Switch.svelte'
  import PricingGrid from './G_PricingGrid.svelte'
  import I18n from './G_I18n.svelte'
  import I from './G_I.svelte'

  import { onMount } from 'svelte'
  import { fly,fade,blur } from 'svelte/transition'

  let localConf={}
  init(localConf)
  $: setCssVars($conf.style)
  if($wsc) $wsc.subscribe("front")

  //$: console.log($conf)
  
  onMount(async () => {
    spyScroll()
	})

  function CTA(){
    openHref("https://app.zestatus.com/?action=try",{location:true})
  }



  let menus=[
    {id: 'top', name: '<i class="fa-light fa-home">'},
    {id: 'features', name: 'h--features'},
    {id: 'pricing', name: 'h--pricing'}
  ]

  let video, showVideoButton=true
  function clickVideo(){
    if(video.paused) {
      video.play()
      showVideoButton=false
    } else {
      video.pause()
      showVideoButton=true
    }
  }

</script>


<div id="spyroot" class="global">

  <!-- Navbar -->
  <nav id="navbar" class="fixed-top navbar navbar-expand-lg">
    <div class="container-fluid">
      <!-- Brand -->
      <a href="./#">
        <img class="navbarBrandImg" src="/img/v2020.2.zestatus.full transparent-large.png" aheight=60 width=150 alt="zeStatus" title="zeStatus">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
        <span class=""><i class="fa-light fa-bars"></i></span>
      </button>

      <!-- Navbar links -->
      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav nav">
          {#each menus as menu}
            <li class="ucfirst nav-item mx-lg-3">
              <a class="nav-link" on:click={()=>{window.location="./#"+ menu.id}} href="./#{menu.id}" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">{@html i18n(menu.name, $lang)}</a>
            </li>
          {/each}
        </ul>

        <ul class="navbar-nav nav ms-auto">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="./#" role="button" aria-expanded="false">
              <I globe me/>{$conf.langNames[$lang]}
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              {#each $conf.langs as langId}
                <li><span class="ucfirst pointer dropdown-item" href="./fr#" on:click={locale(langId)}>{$conf.langNames[langId]}</span></li>
              {/each}
            </ul>
          </li>
        </ul>
        
        <ul class="navbar-nav ml-auto">
          <li class="nav-item nav-btn">
            <a class="btn btn-warning m-1 text-white text-nowrap"  
              href="https://app.zestatus.com" normal=true>
              <I18n>Sign in</I18n>
            </a>
          </li>
        </ul>

      </div>
    </div>
  </nav>


  <!-- main -->
  <main>
    <section id="top">
      <div class="container">

        <div class="row">

          <div class="col-lg mt-5 mb-5 mr-5">
            <div class="slogan"><I18n>slogan</I18n> <span class="brandTextColor">ZeStatus</span>.</div>
            <p class="subSlogan mt-5"><I18n>subSlogan</I18n></p>
            <div class="mt-5">
              <button type="button" class="btn btn-success btn-lg round-border me-2 mb-4" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                <I18n>h--See live demo page</I18n>
              </button>
              <div class="btn btn-lg round-border btn-warning mb-4" on:click={CTA}><I18n>h--Try it for free</I18n></div>
            </div>
          </div>

          <div class="col-lg" style="position: relative;">
            <video on:click={clickVideo} bind:this={video} on:ended={()=>showVideoButton=true} acontrols	poster="/svglang/zestatus-2.svg?lang={$conf.lang}"
              src="https://cdn.zestatus.com/assets/ZeStatusNB.mp4?lang={$conf.lang}">
              <a href="https://cdn.zestatus.com/assets/ZeStatusNB.mp4?lang={$conf.lang}">Download the video</a> file.
              <track kind="captions">
            </video>
            <div on:click={clickVideo} class:hide={!showVideoButton} class="videoButton blink"><i class="fa-regular fa-play-circle"></i></div>
          </div>

        </div>   

      </div>

    </section>


    <section id="features">
      <div class="container">

            <h4 class="my-0 "><I18n>h--Features</I18n></h4>

            <div class="row">
              <ul class="col-md list-unstyled mt-3 mb-4">
                <li><I pager me /> <I18n>h--Status pages</I18n></li>
                <li><I chalkboard-teacher me /> <I18n>h--Subscribers</I18n></li>
                <li><I plug me /> <I18n>h--Webhook</I18n></li>
                <li><I link me /> <I18n>h--Api access</I18n></li>
                <li><I users me /> <I18n>h--Team</I18n></li>
                <li><I cog me /> <I18n>h--Services</I18n></li>
              </ul>
              <ul class="col-md  list-unstyled mt-3 mb-4">
                <li><I calendar-star me /> <I18n>h--Events</I18n></li>
                <li><I monitor-heart-rate me /> <I18n>h--Ssl + http monitors</I18n></li>
                <li><I sms me /> <I18n>h--Sms connection</I18n></li>
                <li><I cogs me /> <I18n>h--Third party integration</I18n></li>
                <li><I lock me /> <I18n>h--Private status pages</I18n></li>
                <li><I language me /> <I18n>h--Multilanguages</I18n></li>
                <li><I globe me /> <I18n>h--Auto translation</I18n></li>
              </ul>
            </div>
           <button type="button" class="btn btn-lg w-100 btn-success"
							on:click={CTA}>
							<I18n>h--try it for free</I18n>
						</button>
            <div><I18n>h--why choose zestatus</I18n></div>
					
      </div>   
    </section>


    <section id="pricing">
      <div class="container">
        <div class="text-center">
          <div class="sectionTitle display-2">{i18n("h--Pricing", $lang)}</div>
          <p class="subSectionTitle">{i18n("h--Clear prices", $lang)}</p>
        </div>

        <div>
          <PricingGrid pricing={$conf.pricing} {CTA} compare={false}/>
        </div> 
      </div>
    </section>


  </main>
  
  <br><br>
  
  <footer class="fixed-bottom">
    <div class="container text-center">
        <span>
          <I copyright me/> {thisYear()} - ZeStatus.com 
          - <a href="/cgu" >CGU</a>
          - <a href="/cgv">CGV</a>
        </span>
    </div>
  </footer>

</div>

<style>
  :root {
    --sectionColorBg:#f6f6f6;
  }
  body {
    font-family: Roboto,Arial,sans-serif;
    font-size: 1.2em;
    font-weight: 600;
  }
  .navbar-brand img {height: 60px;}
  .navbar {background-color: black;}
  .nav-link {color: white;font-weight: 500;}
  .nav-link:hover {color:#00FD00;}

  #top{background-color:var(--brandColor2);color:white;}
  #features{abackground-color: white;color:black;}
  #pricing{abackground-color: white;color:black;}

  .slogan{
    font-size: 3.3rem;
    font-weight: 700;
    line-height: 1.2;
    color:white;
  }
  .subSlogan{
    font-size: 2rem;
    color:white;
  }
  .brandTextColor{color:var(--brandColor);}
  .navbar-toggler{color:var(--brandColor);}
  .navbar-toggler:hover{color:var(--brandColor);}

  #features ul {
    line-height: 1.8;
    font-weight: 400;
  }

  :global(#features i) {color: var(--brandColor);}
  
  footer, footer * {background-color: var(--brandColor2);color:white;font-size: 0.8rem;}

  section:nth-child(2n+1) {background-color:var(--sectionColorBg);}

  section{
    padding-top:76px;
    padding-bottom:2rem;
  }

  .sectionTitle {
    color:var(--brandColor2);
    padding-bottom:2rem;
  }

  
  .subSectionTitle {
    font-size: 1.5rem;
    line-height: 1.5;
    color:var(--brandColor2);
  }

  .sectionContent {
    padding-top:4rem;
  }

  .round-border {border-radius: 20px !important;}

  .videoButton {
    color: grey;
    font-size: 8em;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 40%;
  }

  video:focus {outline:none;}
  video{
      width: 100%;
    height: 56.25%;
  }
  video[poster]{
    height:100%;
    width:100%;
  }
</style>