<script>
  import { conf, lang, customer } from './stores.js'
  import { i18n, asArray, inArray, fuc } from './h.js'
  import Switch from './G_Switch.svelte'
  import I18n from './G_I18n.svelte'

  export let CTA=false
  export let subscription=false
  export let compare=true
  export let pricing={}

  let isYearPlan=false
  function showCurrency(amount, currency='EUR'){
    //console.log($conf.lang,currency)
    return new Intl.NumberFormat($conf.lang, { style: 'currency', currency: currency, currencyDisplay:'symbol',minimumFractionDigits:0, maximumFractionDigits:2 }).format(amount)
  }
</script>

<div class="text-center small"><I18n id="pricinggrid--Pricing-ht"/></div>
          
<div class="mt-4 text-center fs-3">
  <I18n id="pricinggrid--Billing"/>
  <span class="text-success" ><I18n id="pricinggrid--Monthly"/></span>
  <Switch bind:checked={isYearPlan}/>
  <span class="text-success" ><I18n id="pricinggrid--Annualy"/></span>
</div>

<div class="row row-cols-1 row-cols-lg-3 row-cols-xl-3 text-center">
  {#each asArray(pricing.plans) as plan}
    {#if plan.valid 
      && ((!subscription && plan.showInPricing) 
      || (subscription && plan.showInCheckout)
      || (subscription && plan.id==$customer.licence && plan.showInLegacy))
    }
      <div class="col">
        <div class="card mb-4 rounded-3 shadow-sm">
          <div class:best={plan.best} class="card-header py-3">
            <h4 class="my-0 fw-normal"><I18n id={plan.name}/></h4>
            <div class="my-0 small fw-normal"><I18n id={plan.commercial}/></div>
          </div>
          <div class="card-body">
            {#if plan.flex}
              <div class="h1 card-title"><I18n id="pricinggrid--Flex plan"/></div>
            {:else if plan.licence}
              <div class="h1 card-title">{showCurrency(plan.licence)}</div>
              <small class="text-muted fw-light"><I18n id="pricinggrid--for lifetime validity"/></small>
            {:else if plan.uniq}
              <div class="h1 card-title">{showCurrency(plan.uniq)}</div>
              <div class="text-muted fw-light"><I18n id="pricinggrid--One time fee"/></div>
            {:else if plan.d}
              <div class="h1 card-title">{showCurrency(plan.d)}<small class="text-muted fw-light">/<I18n id="pricinggrid--day"/>{plan.unit?`/${i18n(plan.unit, $lang)}`:""}</small></div>
              <small class="text-muted fw-light"><I18n id="pricinggrid--Flex plan"/></small>
            {:else}
              {#if !isYearPlan && plan.m}
                <div class="h1 card-title">{showCurrency(plan.m)}<small class="text-muted fw-light">/<I18n id="pricinggrid--month"/></small></div>
              {/if}
              {#if isYearPlan && plan.y}
                <div class="h1 card-title">{showCurrency(plan.y)}<small class="text-muted fw-light">/<I18n id="pricinggrid--year"/></small></div>
                <small class="text-muted fw-light savings"><I18n id="pricinggrid--You save"/> {showCurrency((plan.m*12 - plan.y))}</small>
              {/if}
            {/if}
            <ul class="list-unstyled mt-3 mb-4">
              {#each plan.details as detail}
                <li>
                  {@html i18n(detail, $lang)}  
                </li>
              {/each}
            </ul>
            {#if plan.contact}
              <button on:click={()=>window.location="#contact"} type="button" class="w-100 btn btn-lg btn-outline-success"><I18n id="pricinggrid--contact us"/> <i class="fa-solid fa-chevron-right"></i></button>
            {:else}
              {#if subscription}
                {#if plan.id==$customer.licence}
                  <div class="w-100 text-success"><I18n id="licence--Your licence"/></div>
                {:else}
                  <button on:click={()=>CTA(plan.id)} type="button" class="w-100 btn btn-lg btn-outline-primary">
                    <I18n id={plan.uniq?"licence--Buy":"licence--Subscribe"}/>
                    <i class="fas fa-chevron-right"></i></button>
                {/if}
              {:else}
                <button on:click={()=>CTA(plan.id)} type="button" class="w-100 btn btn-lg btn-outline-primary"><I18n id="pricinggrid--Free trial"/> <i class="fa-solid fa-chevron-right"></i></button>
                <div class="mt-1 xsmall"><I18n id="pricinggrid--Free trial no card"/></div>
              {/if}
            {/if}
          </div>
        </div>
      </div>
    {/if}
  {/each}
</div>

{#if compare}
  <div>
    <h2 class="display-6 text-center mb-4"><I18n id="pricinggrid--Compare plans"/></h2>
    <div class="text-center small"><I18n id="pricinggrid--Compare plans message"/></div>

    <div class="table-responsive fs-6">
      <table class="table text-center">
        <thead>
          <tr>
            <th style="width: 20%;"></th>
            {#each asArray(pricing.plans) as plan}
              {#if plan.valid && plan.showInCompare}
                <th style="width: 15%;"><I18n id={plan.name}/></th>
              {/if}
            {/each}
          </tr>
        </thead>
        <tbody>
          {#each asArray(pricing.features) as feature}
            {#if !inArray("ALL", feature.not) && feature.valid}
              <tr class:featureGroup={feature.group}>
                <th scope="row" class="text-start"><I18n id={`features--${fuc(feature.name)}`}/></th>
                {#each asArray(pricing.plans) as plan}
                  {#if plan.valid && plan.showInCompare}
                    <td>
                      {#if (!feature.in || inArray(plan.id, feature.in)) && !inArray(plan.id, feature.not)}
                        <i class="fa-solid fa-check"></i>
                      {/if}
                    </td>
                  {/if}
                {/each}
              </tr>
            {/if}
          {/each}
        </tbody>
      </table>
    </div>
  </div>
{/if}


<style>
  .best{background-color: var(--brandColor);color:var(--textOnBrandColor1);}
  .savings{color: green!important;}

  .cta{
    background-color:var(--brandColor2);
    color:var(--brandColor1);
    font-weight: bold;
    padding: 5px 15px;
    cursor: pointer;
  }
  .featureGroup{
    border-color: black;
    border-style: solid;
    border-width: 0;
  }
</style>