import { writable, get } from 'svelte/store'
import _merge from 'lodash/merge'

export const conf = writable({})

export const online = writable(false)
export const modal = writable({})
export const alert = writable({})
export const spinner = writable(false)
export const menus = writable([])

export const route = writable("/")
export const routeRoot = writable("/")
export const routeMatched = writable(false)
export const routeReload = writable(false)
export const routeU = writable("/")
export const routeRootU = writable("/")
export const routeMatchedU = writable(false)
export const routeReloadU = writable(false)

export const wsc = writable(false) // wss client
export const wscOnline = writable(-1) // wss client online state

export const w = writable({}) // window properties (height, width, xorigin)

/************************************ */
export const userId = writable(false) // current userId
export const user = writable({}) // current user

export const uuid = writable(doLocalStorage('uuid') || uuidV4())
uuid.subscribe((value) => doLocalStorage('uuid', value))

export const lang = writable(doLocalStorage('lang') || '')
lang.subscribe((value) => doLocalStorage('lang', value))

export const pwaInstalled = writable(doLocalStorage('pwaInstalled') || 'no')
pwaInstalled.subscribe((value) => doLocalStorage('pwaInstalled', value))
export const pwaPrompt = writable(false)

export const customerId = writable(doLocalStorage('customerId') || null)
customerId.subscribe((value) => doLocalStorage('customerId', value))
export const customer = writable({}) // current customer
export const customers = writable(false) // customers list
export const permissions = writable({}) // user permissions for a customer

export const token = writable(doLocalStorage('token') || null)
token.subscribe((value) => doLocalStorage('token', value))

function doLocalStorage(id, value){
  let prefix=''
  if(typeof localStorage !=='undefined' ){
    if(value!=null) localStorage[prefix+id] = JSON.stringify(value)
    else return JSON.parse(localStorage.getItem(prefix+id))
  }
}

/*if (typeof window !== 'undefined') window.addEventListener("storage", localStorageSetHandler, false)
function localStorageSetHandler(e){
  console.log(e)
}*/

function uuidV4(noDash) {
  let f='xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  if(noDash) f='xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'
  return f.replace(/[xy]/g, function(c) {
  let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
  return v.toString(16)
  })
}

// --------------------------------------------
// ----- external stores access (-;)
// --------------------------------------------
let stores={
  w:w,
  online:online,
  modal:modal,
  alert:alert,
  menus:menus,
  spinner:spinner,
  route:route,
  routeRoot:routeRoot,
  routeMatched:routeMatched,
  routeReload:routeReload,
  routeU:routeU,
  routeRootU:routeRootU,
  routeMatchedU:routeMatchedU,
  routeReloadU:routeReloadU,
  lang:lang,
  uuid:uuid,
  pwaInstalled:pwaInstalled,
  pwaPrompt:pwaPrompt,
  conf:conf,
  wsc:wsc,
  wscOnline:wscOnline,
  token:token,
  user:user,
  userId:userId,
  customerId:customerId,
  customer:customer, 
  customers:customers,
  permissions:permissions
}
export function _s(storeName, obj, replace=false) {
    if(!stores[storeName]) return
  let store=stores[storeName]
  if(obj!=null) {
    if(replace) store.set(obj)
    else store.update(cur => {
      if(typeof obj !== "object" || typeof cur !== "object") return obj
      return _merge(cur, obj)
    })
    return get(store)
  } else return get(store)
}